



















































































import { Component, Vue } from 'vue-property-decorator'
import OrderProvider from '@/resources/order.provider'
import { mapActions, mapState } from 'vuex'
import DesktopPosNavbar from '@/components/desktop-pos/navbar.vue'

const OrderService = new OrderProvider()

@Component({
  components: {
    DesktopPosNavbar
  },
  computed: {
    ...mapState({
      selectBranch: (state: any) => state.Store.selectBranch,
      selectPos: (state: any) => state.Store.selectPos
    })
  },
  methods: {
    ...mapActions({
      getStepPricing: 'Cart/getStepPricing',
      setItems: 'ExchangeOrder/setItems',
      setBill: 'ExchangeOrder/setBill',
      setDiscount: 'ExchangeOrder/setDiscount',
      setPromotionCode: 'ExchangeOrder/setPromotionCode',
      setReturnItems: 'ExchangeOrder/setReturnItems',
      setOldNet: 'ExchangeOrder/setOldNet'
    })
  }
})
export default class ProductListPage extends Vue {
  getStepPricing!: any

  search = ''

  orders: any[] = []

  setOldNet!: any

  setItems!: any

  setBill!: any

  selectBranch!: any

  selectPos!: any

  setReturnItems!: any

  setPromotionCode!: any

  setDiscount!: any

  columns = [
    {
      field: 'orderId',
      label: 'ID',
      width: '40',
      numeric: true
    },
    {
      field: 'first_name',
      label: 'First Name'
    }
  ]

  loading = false

  get searchQuery (): string | (string | null)[] {
    return this.$route.query.search || ''
  }

  async mounted (): Promise<void> {
    await this.getStepPricing()
    if (this.searchQuery !== '') {
      this.search = `${this.searchQuery}`
      this.searchOrder()
    }
  }

  get type (): any {
    return this.$route.query.type ? this.$route.query.type : 'receipt'
  }

  async chooseOrder (order: any): Promise<void> {
    const { data } = await OrderService.getOrderById(order.orderId)
    this.setOldNet(+data.net)
    data.skus = this.spiltOrderItem(data)
    this.setItems(data.skus)
    this.setBill(data)
    this.setReturnItems([])
    const promo = order?.promotionCode?.code ? order.promotionCode : null
    this.setPromotionCode(promo)
    this.setDiscount(promo !== null ? order.discount : 0)
    this.$router.push({ name: 'ExchangeCart', params: { orderId: order.orderId } })
  }

  // eslint-disable-next-line class-methods-use-this
  spiltOrderItem (orders: any): any[] {
    const result: any[] = []
    const allItems = []
    for (const order of orders.subOrders) {
      const items = order.skus.map((sk: any) => ({
        ...sk,
        warehouse: order.warehouse
      }))
      allItems.push(...items)
    }
    allItems.forEach((element: any) => {
      for (let i = 0; i < element.amount; i++) {
        result.push({
          ...element,
          operation: 'old_item',
          price: element.price / element.amount,
          fullPrice: element.fullPrice / element.amount,
          amount: 1
        })
      }
    })
    return result
  }

  searchOrder (): void {
    this.getOrders()
  }

  async getOrders (): Promise<void> {
    try {
      this.loading = true
      const { data: { results } } = await OrderService.getOrders({
        page: 1,
        limit: 25,
        search: this.search.trim(),
        channel: 'all',
        currentState: 'completed'
      })
      if (results.length === 0) {
        this.$buefy.dialog.alert('ไม่พบ Order')
      }
      this.orders = results
    } catch (err: any) {
      this.$buefy.dialog.alert(err.message)
    } finally {
      this.loading = false
    }
  }
}
