


































import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({})
export default class DesktopPosNavbar extends Vue {
  @Prop()
  selectBranch!: any

  @Prop()
  selectPos: any

  get posIsSelected (): boolean {
    return this.selectPos && this.selectPos?.posId
  }
}
